$(document).ready(function(){
  $(document).on('click', '.alterar-senha', function(){
    $('input[type="password"]').removeClass('error');
    $('.problema').addClass('d-none');
    $('.sucesso').addClass('d-none');
    
    var id_aluno = $(this).data('id');
    var data = $(this).parents('form').serializeArray();
    var senha = data.find(function(d) { return d.name == 'senha'; });
    var confirmacao = data.find(function(d) { return d.name == 'confirmacao'; });

    $('input[type="password"]').each(function(){
      if($(this).val() == ""){
        $(this).addClass('error');
      }
    });

    if($('input[type="password"].error').length > 0){
      var pluralS = $('input[type="password"].error').length > 1 ? 's' : '';
      var pluralM = $('input[type="password"].error').length > 1 ? 'm' : '';
      $('.problema').removeClass('d-none');
      $('.problema .descricao').html('O' + pluralS + ' campo' + pluralS + ' não pode' + pluralM + ' estar em branco.');
    } else if(senha.value != confirmacao.value){
      $('input[type="password"]').addClass('error');
      $('.problema').removeClass('d-none');
      $('.problema .descricao').html('As senhas não conferem. Você precisa inserir a mesma senha nos dois campos acima.');
    }

    if($('input[type="password"].error').length <= 0){
      $.ajax({
        url: '../functions.php',
        type: 'POST',
        cache : false,
        data: { funcao: 'alterarSenha', id: id_aluno, senha: senha.value },
        beforeSend: function() {
          $('.salvando').removeClass('d-none');
          $('.alterar-senha').addClass('d-none');
        }
      })
      .done(function(data) { 
        $('.sucesso').removeClass('d-none');
      })
      .always(function(jqXHR, textStatus){ 
        $('.salvando').addClass('d-none');
        $('.alterar-senha').removeClass('d-none');
      });
    }
  });

  $(document).on('click', '.recuperar-senha', function(){
    $('.error').addClass('d-none');
    $('.success').addClass('d-none');

    var cpf = $('input[name="cpf"]').val();
    if(isNaN(cpf) || cpf == ""){
      $('.error').removeClass('d-none');
      $('.error').html('Insira somente os números do seu CPF.');
    } else{
      $.ajax({
        url: '../functions.php',
        type: 'POST',
        cache : false,
        dataType: 'json',
        data: { funcao: 'recuperarSenha', cpf: cpf },
        beforeSend: function() {
          $('.buscando').removeClass('d-none');
          $('.recuperar-senha').addClass('d-none');
        }
      })
      .done(function(data) {
        $.ajax({
          url: '../send-email.php',
          type: 'post',
          data: {
            tipo: 'recuperar_senha',
            nome: data.nome,
            email: data.email,
            recuperar: data.recuperar
          }
        }).done(function(retorno){
        	if(retorno.toLowerCase().includes('erro')){
        		$('.recuperar-senha').removeClass('d-none');
        		$('.error').removeClass('d-none');
        		$('.error .msg').html(retorno);
        	} else {
        		var maskEmail = data.email.split('');
        		var control = maskEmail.length;

        		for (var i = 0; i < maskEmail.length; i++) {
        		  if(maskEmail[i] == "@"){
        		    control = i;
        		  }
        		  if(i >= 2 && i < control){
        		    maskEmail[i] = "*";
        		  }
        		}

        		$('.success').removeClass('d-none');
        		$('.success').html('Foi enviado um email para: <strong>' + maskEmail.join('') + '</strong> com instruções para recuperar sua senha.');
        		$('.recuperar-senha').addClass('d-none');
        	}
        })
        .always(function(jqXHR, textStatus){ 
          $('.buscando').addClass('d-none');
        });
      })
      .fail(function(jqXHR, textStatus){ 
        $('.error').removeClass('d-none');
        $('.error').html('O CPF informado não consta em nosso sistema. Verifique se o número está correto e tente novamente.');

        $('.buscando').addClass('d-none');
        $('.recuperar-senha').removeClass('d-none');
      });
    }
  });
});