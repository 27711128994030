$(document).ready(function(){
	var isScroll = true;
	var loading = false;

	$(window).scroll(function() {
		var margin = $(window).height() / 2;
		if(window.location.href.indexOf('candidates') >= 0){
			if($(window).scrollTop() + $(window).height() + margin >= $(document).height()) {
				last = $('.card:last').attr('id');
				loadingDataCandidates(last);
			}
		}
	});

	function loadingDataBillet(){
		if(!loading){
			$.ajax({
				url: '../functions.php',
				type: 'POST',
				cache : false,
				data: { funcao: 'listarAlunosAtivos' },
				beforeSend: function() {
					$('.loading').show();
					loading = true;
				}
			})
			.done(function(data) { 
				$('#tabs').append(data);
				$('.gerenciar-boleto[name="pagamento"]').datepicker({
					dateFormat: "dd/mm/yy"
				});
			})
			.always(function(jqXHR, textStatus){ 
				$('.loading').hide();
				if(textStatus.length <= 0){
					loading = true;	
				} else {
					loading = false;	
				}
			});
		}
	}

	function loadingDataStudents(){
		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'listarTodosAlunos' },
			beforeSend: function() {
				$('.loading').show();
				loading = true;
			}
		})
		.done(function(data) { 
			$('#tabs').append(data);
			$('[data-toggle="tooltip"]').tooltip();
		})
		.always(function(jqXHR, textStatus){ 
			$('.loading').hide();
			if(textStatus.length <= 0){
				loading = true;	
			} else {
				loading = false;	
			}
		});
	}

	function loadingDataCandidates(last){
		if(!loading && isScroll){
			$.ajax({
				url: '../functions.php',
				type: 'POST',
				cache : false,
				data: { funcao: 'listarCandidatos', ultimo: last },
				beforeSend: function() {
					$('.loading').show();
					loading = true;
				}
			})
			.done(function(data) { 
				if(data === ""){
					isScroll = false;
				}
				$('#accordion').append(data);
				$('[data-toggle="tooltip"]').tooltip();
			})
			.always(function(jqXHR, textStatus){ 
				$('.loading').hide();
				if(textStatus.length <= 0){
					loading = true;	
				} else {
					loading = false;	
				}
			});
		}
	}

	function loadingDataScholarships(){
		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'listarBolsistas' },
			beforeSend: function() {
				$('.loading').show();
				loading = true;
			}
		})
		.done(function(data) { 
			$('#data').append(data);
			$('[data-toggle="tooltip"]').tooltip();
		})
		.always(function(jqXHR, textStatus){ 
			$('.loading').hide();
			if(textStatus.length <= 0){
				loading = true;	
			} else {
				loading = false;	
			}
			getQuantidadeBolsas();
		});
	}

	function getQuantidadeBolsas(){
		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'quantidadeBolsas' },
			beforeSend: function() {
				var spin = $('<i/>').addClass('fas fa-circle-notch fa-spin');
				$('.estatistica .valor').html($(spin).clone());
			}
		})
		.done(function(data) { 
			var quantidades = JSON.parse(data);
			$.each(quantidades, function(i, q){
				var key = Object.keys(q)[0];
				if(key != 'ultimo'){
					$('.estatistica .valor[data-value="' + key + '"]').html(q[key]);	
				} else{
					$('.bolsista .ultimo-editado').remove();
					
					var lastEdit = $('<div/>').addClass('ultimo-editado col-sm-12');
					var spanLastEdit = $('<span/>').html('Editado por último');
					lastEdit.append(spanLastEdit);
					
					$('#' + q[key]).prepend(lastEdit);
				}
			});
		});
	}

	function atualizarBolsista(event){
		var bolsista = $(event).parents('.bolsista');
		var dados = {
			"id_candidato": $(bolsista).attr('id'),
			"status": $(bolsista).find('select[name="status"] option:selected').val(),
			"porcentagem": $(bolsista).find('select[name="porcentagem"] option:selected').val(),
			"tipo_bolsa": $(bolsista).find('select[name="tipo-bolsa"] option:selected').val(),
			"observacao": $(bolsista).find('textarea').val()
		};
		
		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'atualizaBolsista', dados: dados }
		})
		.done(function(data){
			getQuantidadeBolsas();
		});
	}

	if($('.call-function').length > 0){
		switch($('.call-function').data('func')){
			case 'data-candidates':
				loadingDataCandidates(0);
				break;
			case 'data-scholarships':
				loadingDataScholarships();
				break;
			case 'data-students':
				loadingDataStudents();
				break;
			case 'data-billet':
				loadingDataBillet();
				break;
		}
	}

	var year = getCookie("year");
	$(".btn.current-year").removeClass("active");
	if(!year.trim()){
		year = $("input[name='app-year']").val();
		setCookie("year", year, 30);
	}
	$(".btn.current-year[data-ref='" + year + "']").addClass("active");

	$(document).on('click', '.btn.current-year', function() {
		var current = $(this).data('ref');
		
		$(".info-year").html(current);
		
		setCookie("year", current, 30);
		$(".btn.current-year").removeClass("active");
		$(this).addClass("active");
	});

	$(document).on('click', '.checkbox-ativo input[type="checkbox"]', function() {
		var dados = {
			"id": $(this).val(),
			"valido": $(this).prop('checked')
		};

		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'atualizaStatusAluno', dados: dados }
		})
		.done(function(data){
			console.log("save status");
		});
	});

	$('#monografiaModal').on('show.bs.modal', function (e) {
		var ref = $(e.relatedTarget).attr('data-ref');
		var type = $(e.relatedTarget).attr('data-type');
		
		$('#monografiaModal #fcId').val(ref);
		$('#monografiaModal #btnSalvar').attr("data-type", type);
		
		if(type == 'edit'){
			$.ajax({
				url: '../functions.php',
				type: 'POST',
				cache : false,
				data: { funcao: 'obterMonografiaPorAluno', id: ref }
			})
			.done(function(data){
				var monografia = JSON.parse(data);
				$('#monografiaModal #fcTitulo').val(monografia.titulo);
				$('#monografiaModal #fcResumo').val(monografia.resumo);
				$('#monografiaModal #fcArquivo').val(monografia.arquivo);
			});
		}
	});

	$('#monografiaModal').on('hidden.bs.modal', function (e) {
		$('#monografiaModal #fcId').val("");
		$('#monografiaModal #fcTitulo').val("");
		$('#monografiaModal #fcResumo').val("");
		$('#monografiaModal #fcArquivo').val("");
		$('#monografiaModal #btnSalvar').removeAttr("data-type");
	});

	$(document).on('click', '#monografiaModal #btnSalvar', function() {
		var ref = $('#monografiaModal #fcId').val();
		var type = $(this).attr("data-type");

		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { 
				funcao: 'salvarMonografia', 
				id: ref, 
				tipo: type,
				titulo: $('#monografiaModal #fcTitulo').val(), 
				resumo: $('#monografiaModal #fcResumo').val(),
				arquivo: $('#monografiaModal #fcArquivo').val()
			}
		})
		.done(function(data){
			if(data){
				if(type == 'insert'){
					var button = $('button[data-ref="' + ref + '"]');
					button.attr("data-type", "edit");
					button.html("Editar");

					var icon = button.siblings('span[data-toggle="tooltip"]');
					icon.attr("data-original-title", "Cadastrada");
					icon.empty();
					icon.append($("<i/>").addClass("fas fa-check-circle text-success"));
				}

				$('#monografiaModal').modal('hide');
			}
		});
	});

	$(document).on('click', '.salvar-boleto', function() {
		var _this = this;
		$(_this).find('.btn-save').addClass('d-none');
		$(_this).find('.btn-loading').removeClass('d-none');
		
		var form = $(_this).parents('form');
		var candidato = $(_this).data('candidato');
		var boletoOp = form.find('.gerenciar-boleto[name="boleto"] option:selected');
		var status = form.find('.gerenciar-boleto[name="status"]').val();
		var pagamento = form.find('.gerenciar-boleto[name="pagamento"]').val();
		pagamento = moment(pagamento, 'DD-MM-YYYY').format('YYYY-MM-DD');
		var valor = form.find('.gerenciar-boleto[name="valor"]').val();
		var boleto = 	"id_candidato=" + candidato +
									"&id_boleto=" + boletoOp.data('id') +
									"&referencia=" + boletoOp.val().split('-')[0] +
									"&parcela=" + boletoOp.val().split('-')[1] +
									"&status=" + status +
									"&pagamento=" + pagamento +
									"&valor=" + valor;

		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'gerenciarBoleto', dados: boleto }
		})
		.done(function(data){
			var select = form.find('.gerenciar-boleto[name="boleto"]');
			var position;

			select.find('option').each(function(i){
				if($(this).prop('selected')){
					$(this).remove();
					position = i;
					return false;
				}
			});

			var op = $('<option/>')
								.val(boletoOp.val())
								.html(boletoOp.html())
								.prop('selected', true)
								.attr('data-id', data != 0 ? data : boletoOp.data('id'))
								.attr('data-status', status)
								.attr('data-pagamento', pagamento)
								.attr('data-valor', valor);
			select.find('option').eq(position).before(op);
			
			var bolinha;
			if(boletoOp.val().split('-')[0] == 'mensalidade'){
				form.find('.bolinha[data-ref="' + boletoOp.val().split('-')[0] + '"]').each(function(){
					if($(this).html() == boletoOp.val().split('-')[1].padStart(2, '0')){
						bolinha = $(this);
					}
				});
			} else{
				bolinha = form.find('.bolinha[data-ref="' + boletoOp.val().split('-')[0] + '"]');
			}

			if(status == 0){
				bolinha.addClass('nao-pago').removeClass('pago');
			} else{
				bolinha.removeClass('nao-pago').addClass('pago');
			}
			
			form.find('.icon-saved').removeClass('d-none');
		})
		.always(function(){
			$(_this).find('.btn-save').removeClass('d-none');
			$(_this).find('.btn-loading').addClass('d-none');
		});
	});

	$(document).on('change', 'select.gerenciar-boleto[name="boleto"]', function() {
		var form = $(this).parents('form');
		var boleto = $(this).find(':selected');

		form.find('.icon-saved').addClass('d-none');
		form.find('.gerenciar-boleto[name="status"]').val(boleto.data('status'));
		form.find('.gerenciar-boleto[name="pagamento"]').datepicker('setDate', new Date(boleto.data('pagamento')));
		form.find('.gerenciar-boleto[name="valor"]').val(boleto.data('valor'));

		if($(this).val() == ""){
			form.find('.salvar-boleto').addClass('d-none');
		} else{
			form.find('.salvar-boleto').removeClass('d-none');
		}
	});

	$(document).on('click', '.limpar-busca', function() {
		$(this).siblings('.buscar-nome').val('');
		$(this).addClass('d-none');
		$('.card').removeClass('d-none');
	});

	$(document).on('keyup', '.buscar-nome[name="search"]', function() {
		var s = $(this).val().toLowerCase();
		if(s.length <= 0){
			$(this).siblings('.limpar-busca').addClass('d-none');
		} else{
			$(this).siblings('.limpar-busca').removeClass('d-none');
		}

		$('.card .nome').each(function(){
			if($(this).data('name').toLowerCase().includes(s)){
				$(this).parents('.card').removeClass('d-none');
			} else{
				$(this).parents('.card').addClass('d-none');
			}
		});
	});
	
	$(document).on('click', '.abrir-dados', function() {
		$(this).addClass('d-none');
		$(this).parent('li').children('.fechar-dados').removeClass('d-none');
		
		$('.card-collapse').collapse('show');
	});

	$(document).on('click', '.fechar-dados', function() {
		$(this).addClass('d-none');
		$(this).parent('li').children('.abrir-dados').removeClass('d-none');

		$('.card-collapse').collapse('hide');
	});

	$(document).on('change', 'select[name="tipo"]', function() {
		if($(this).val() == 'pagamento'){
			$('select[name="referencia"]').removeClass('d-none');
		} else {
			$('select[name="referencia"]').addClass('d-none');
		}
	});

	$(document).on('change', '.bolsista select[name="status"], .bolsista select[name="porcentagem"], .bolsista select[name="tipo-bolsa"]', function() {
		atualizarBolsista(this);
	});	

	$(document).on('keyup', '.bolsista textarea', function() {
		var self = this;
	  delay(function(){
	    atualizarBolsista(self);
	  }, 1000);
	});

	var delay = (function(){
	  var timer = 0;
	  return function(callback, ms){
	  clearTimeout (timer);
	  timer = setTimeout(callback, ms);
	 };
	})();
	
	$(document).on('click', '.atualiza-pagadores', function(){
		var cpfs = $('textarea[name=cpfs]').val();
		var referencia = $('select[name=referencia]').val();

		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'atualizaStatusBoleto', cpfs: cpfs, status: 1, referencia: referencia }
		})
		.done(function(data){
			$('.retorno').append('Número de CPF atualizados: ', data);
		});
	});

	$(document).on('click', '.coordenadas', function(){
		var inicio = $('#inicio').val();
		var fim = $('#fim').val();
		$.ajax({
			url: '../functions.php',
			type: 'POST',
			cache : false,
			data: { funcao: 'listaLocalidades', inicio: inicio, fim: fim }
		})
		.done(function(data) { 
			var i = 0;
			var request = setInterval(function(){
				var item = JSON.parse(data)[i];
				if(item.id == fim) { clearInterval(request); }
				var bairro = item.bairro.replace(/\s/g, '+');
				var localidade = item.localidade.replace(/\s/g, '+');
				var uf = item.uf;

				$.ajax({
					url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + bairro + ',' + localidade + ',' + uf + ',BR&key=AIzaSyDIStaJR59f3-xatKXdVlhQeRnr_VaHyJs',
					type: 'GET',
					dataType: 'JSON',
					cache : false
				})
				.done(function(data){
					if(data.status == 'OK'){
						if(data.results.length > 0){
							var address = data.results[0];
							$('.lat-lng').append('{ lat: ' + address.geometry.location.lat + ', lng: ' + address.geometry.location.lng + ' },<br>');
						}
					} else {
						console.log(data.status);
					}
				});
				i++;
			}, 1000);
		});
	});

	$(document).on('click', '.enviar-email-admin', function(){
		var data = $('textarea[name=dados]').val();
		var last = $('input[name=ultimo]').val();
		var typeEmail = $('select[name=tipo]').val();
		data = JSON.parse(data);
		
		var i = 0;
		var request = setInterval(function(){
			var candidato = data[i];
			if(candidato.id == last) { clearInterval(request); }

			switch(typeEmail) {
				case 'pendencia':
					$.ajax({
						url: '../send-email.php',
						type: 'post',
						data: {
							tipo: typeEmail,
							nome: candidato.nome,
							email: candidato.email,
							boleto: candidato.pendencias.boleto,
							rg: candidato.pendencias.rg,
							cpf: candidato.pendencias.cpf,
							residencia: candidato.pendencias.residencia,
							diploma: candidato.pendencias.diploma
						}
					}).done(function(retorno){
						var div = $('<div/>').html('ID: ' + candidato.id + ' | Nome: ' + candidato.nome + ' | Email: ' + candidato.email + ' | Retorno: ' + retorno);
						$('.retorno-email').append(div);
					});
					break;
				case 'aprovado':
					$.ajax({
						url: '../send-email.php',
						type: 'post',
						data: { tipo: typeEmail, nome: candidato.nome, email: candidato.email, cpf: candidato.cpf }
					}).done(function(retorno){
						var div = $('<div/>').html('ID: ' + candidato.id + ' | Nome: ' + candidato.nome + ' | Email: ' + candidato.email + ' | CPF: ' + candidato.cpf + ' | Retorno: ' + retorno);
						$('.retorno-email').append(div);
						if(retorno){
							$.ajax({
								url: '../functions.php',
								type: 'POST',
								cache : false,
								data: { funcao: 'atualizaStatusAprovado', id: candidato.id }
							})
							.done(function(data){
								var div = $('<div/>').html('ID: ' + candidato.id + ' | Atualizado: Aprovado | Linhas alteradas: ' + data);
								$('.retorno-banco').append(div);
							});
						}
					});
					break;
				case 'pagamento':
					var refValor = $('select[name=referencia] option:selected').text();
					$.ajax({
						url: '../send-email.php',
						type: 'post',
						data: { tipo: typeEmail, nome: candidato.nome, email: candidato.email, acao: refValor }
					}).done(function(retorno){
						var div = $('<div/>').html('ID: ' + candidato.id + ' | Nome: ' + candidato.nome + ' | CPF: ' + candidato.cpf + ' | Retorno: ' + retorno);
						$('.retorno-email').append(div);
						if(retorno){
							var referencia = $('select[name=referencia]').val();
							$.ajax({
								url: '../functions.php',
								type: 'POST',
								cache : false,
								data: { funcao: 'atualizaStatusBoleto', id: candidato.id, status: 1, referencia: referencia }
							})
							.done(function(data){
								var div = $('<div/>').html('ID: ' + candidato.id + ' | Linhas alteradas: ' + data);
								$('.retorno-banco').append(div);
							});
						}
					});
					break;
				default:
					$.ajax({
						url: '../send-email.php',
						type: 'post',
						data: { tipo: typeEmail, nome: candidato.nome, email: candidato.email }
					}).done(function(retorno){
						var div = $('<div/>').html('ID: ' + candidato.id + ' | Nome: ' + candidato.nome + ' | Email: ' + candidato.email + ' | Retorno: ' + retorno);
						$('.retorno-email-large').append(div);
					});
					break;
			}			
			i++;
		}, 2000);
	});

	$(document).on('click', '.separa-status', function(){
		var dados = $('textarea[name=dados]').val();
		var data = JSON.parse(dados);
		var candidatosOk = [];
		var candidatosPendentes = [];
		var candidato;
		var tipoSeparacao = $('select[name=tipo]').val();

		$('.retorno-ok .lista').empty();
		$('.retorno-ok .copy-paste').empty();
		$('.retorno-pendente .lista').empty();
		$('.retorno-pendente .copy-paste').empty();

		for(var i in data){
			if(!data[i].pendencias.boleto && !data[i].pendencias.rg && !data[i].pendencias.cpf && !data[i].pendencias.residencia && !data[i].pendencias.diploma){
				candidato = { "id" : data[i].id, "nome" : data[i].nome, "email" : data[i].email, "cpf" : data[i].cpf };
				candidatosOk.push(candidato);
			} else{
				candidato = { "id" : data[i].id, "nome" : data[i].nome, "email" : data[i].email, "cpf" : data[i].cpf };
				var pendencias = {};
				
				if(data[i].pendencias.boleto){ pendencias.boleto = true; }
				if(data[i].pendencias.rg){ pendencias.rg = true; }
				if(data[i].pendencias.cpf){ pendencias.cpf = true; }
				if(data[i].pendencias.residencia){ pendencias.residencia = true; }
				if(data[i].pendencias.diploma){ pendencias.diploma = true; }

				candidato.pendencias = pendencias;
				
				switch(tipoSeparacao){
					case 'documentos':
						if(!data[i].pendencias.boleto){
							candidatosPendentes.push(candidato);
						}
						break;

					case 'boleto':
						if(!data[i].pendencias.rg && !data[i].pendencias.cpf && !data[i].pendencias.residencia && !data[i].pendencias.diploma){
							candidatosPendentes.push(candidato);
						}
						break;

					case 'ambos':
						candidatosPendentes.push(candidato);
						break;
				}			
			}
		}
		$('.retorno-ok .lista').html(JSON.stringify(candidatosOk, null, 2));
		$('.retorno-ok .copy-paste').html(JSON.stringify(candidatosOk, null, 2));
		$('.retorno-ok .quantidade > span').html(candidatosOk.length);

		$('.retorno-pendente .lista').html(JSON.stringify(candidatosPendentes, null, 2));
		$('.retorno-pendente .copy-paste').html(JSON.stringify(candidatosPendentes, null, 2));
		$('.retorno-pendente .quantidade > span').html(candidatosPendentes.length);
	});

	$(document).on('click', '.select-code', function(){
		switch($(this).attr('data-ref')){
			case 'ok':
				$('.retorno-ok .copy-paste').select();
				break;
			
			case 'pendente':
				$('.retorno-pendente .copy-paste').select();
				break;

			case 'analise':
				$('.copy-paste').select();
				break;
		}
		document.execCommand('copy');
	});

	$(document).on('click', '.matricular-candidato', function(){
		$('.retorno .lista').empty();
		var json = $('textarea[name=dados]').val();
		var candidatos = JSON.parse(json);

		for (let cand of candidatos) {
			var dados = `nome=${cand.nome}&email=${cand.email}&id-candidato=${cand.id}&cpf=${cand.cpf}&opcao=1`;
	
			$.ajax({
				url: "../functions.php",
				type: 'post',
				data: { funcao: 'matricularAluno', dados: dados }
			})
			.done(function(data) { 
				// console.log(data);
			});

		}
	});

});


function initMap(){
	if($('#map').length > 0){
		var map = new google.maps.Map(document.getElementById('map'), {
			zoom: 4,
			center: {lat: -14.235, lng: -51.925}
		});

		var markers = locations.map(function(location, i) {
			return new google.maps.Marker({
				position: location,
				icon: '../dist/imgs/markerclusterer/pin.png'
			});
		});

		var markerCluster = new MarkerClusterer(map, markers,
			{imagePath: '../dist/imgs/markerclusterer/m'});
	}
}

