var arrFileUpload = [
    {id: "fuRg", add: false, fu: ''},
    {id: "fuCpf", add: false, fu: ''},
    {id: "fuResidencia", add: false, fu: ''},
    {id: "fuDiploma", add: false, fu: ''},
    {id: "fuHistorico", add: false, fu: ''}
];
var maxFileSize = 8000000;

$(document).ready(function () {
    fillFormDev();

    //Animate to elements show
    window.sr = ScrollReveal({
        duration: 500,
        scale: 1,
        distance: '100px',
        viewFactor: 0.5
    });

    sr.reveal('#mc_embed_signup', {duration: 1000, scale: 0.1, distance: '0', viewFactor: 1});
    sr.reveal('.steps .step', {origin: 'bottom', distance: '50px'}, 100);
    sr.reveal('.infos .item .link', {origin: 'bottom', distance: '50px'}, 100);
    sr.reveal('.calendario .item:nth-child(even)', {duration: 800, origin: 'right', distance: '10px'}, 300);
    sr.reveal('.calendario .item:nth-child(odd)', {duration: 800, origin: 'left', distance: '10px'}, 300);
    // sr.reveal('.docente .foto', { distance: '20px', origin: 'bottom' });
    // sr.reveal('.docente .nome', { distance: '20px', origin: 'left', delay: 50 });
    // sr.reveal('.docente .resumo', { distance: '20px', origin: 'left', delay: 100 });
    // sr.reveal('.docente .more', { distance: '20px', origin: 'left', delay: 150 });
    // sr.reveal('.docente .external', { distance: '20px', origin: 'left', delay: 150 }, 100);
    sr.reveal('.thumbnail .foto', {distance: '20px', origin: 'bottom'}, 50);
    sr.reveal('.thumbnail .caption', {distance: '0', delay: 50}, 50);
    sr.reveal('.logos .logo', {duration: 400, distance: '50px', origin: 'bottom', delay: 150}, 100);
    sr.reveal('.depoimentos', {duration: 400, distance: '50px', origin: 'bottom', delay: 150, viewFactor: 0.2}, 200);
    sr.reveal('.galeria-fotos .foto', {duration: 500, scale: 0.7, distance: '0'}, 200);
    //

    $('.calendario .item').each(function () {
        var _deadline = $(this).attr('data-ref');

        var dateNow = new Date();
        var dateLimit = new Date(_deadline);

        if (+dateNow > +dateLimit) {
            $(this).addClass('passado');
        }
    });

    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();

    $(document).on('click', '.conteudo .docente .more', function () {
        $(this).html('Mais detalhes');

        if ($(this).attr('aria-expanded') == 'true') {
            $(this).html('Fechar');
        }
    });

    $('.cronograma .link > a').hover(
        function () {
            $('.cronograma').addClass('active');
        }, function () {
            $('.cronograma').removeClass('active');
        }
    );

    $('.open-all-collapse').on('click', function () {
        $('.collapse:not(.navbar-collapse)').collapse('show');
    });

    $('.close-all-collapse').on('click', function () {
        $('.collapse:not(.navbar-collapse)').collapse('hide');
    });

    $('.collapse').on('show.bs.collapse', function () {
        $(this).siblings('.card-header').find('span.arrow').addClass('opened');
    });

    $('.collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.card-header').find('span.arrow').removeClass('opened');
    });

    var isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    if (window.location.pathname.search('inscreva') >= 0 || window.location.pathname.search('matricula') >= 0) {
        $('body').on('click', function () {
            if ($('#inscricaoConcluida').val() == "false" || $('#matriculaConcluida').val() == "false") {
                window.onbeforeunload = function () {
                    return 'Os dados do formulário não foram salvos, deseja permanecer nesta página?';
                };
            }
        });
    }

    $('.corpo-docente .bio').each(function () {
        var cut = 345;
        var collapse = "";
        var bio = $(this).html();

        if (bio.length > cut) {
            var plusCut = bio.slice(cut, -1);
            cut += parseInt(plusCut.indexOf(" "));

            collapse = bio.slice(cut, -1);
            bio = bio.slice(0, cut).trim() + "...";
        } else {
            $(this).siblings('.more').addClass('d-none');
        }

        $(this).html(bio);
        $(this).siblings('.collapse').html(collapse);
    });

    $('.corpo-docente .collapse').on('show.bs.collapse', function () {
        var html = $(this).siblings('.bio').html().replace("...", "");
        $(this).siblings('.bio').html(html);
    });

    $('.corpo-docente .collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.bio').append("...");
    });

    $('select').on('change', function () {
        if ($(this).val() == '') {
            $(this).addClass('choose');
        } else {
            $(this).removeClass('choose');
        }
    });

    $('input[name="trabalho"]').on('change', function () {
        $('input[name="empresa"]').val('');
        $('input[name="cargo"]').val('');
        if ($('input[name="trabalho"]:checked').val() == 'Sim') {
            $('.dados-profissionais').removeClass('d-none');
        } else {
            $('.dados-profissionais').addClass('d-none');
        }
    });

    $(document).on('change', 'input.experiencia-condicional', function () {
        var detalhes = $(this).parents('.wrap-experiencia').find('.detalhes');
        var textarea = detalhes.find('textarea');
        textarea.val('');
        if ($(this).val() == 'Sim') {
            detalhes.removeClass('d-none');
            textarea.addClass('required');
        } else {
            detalhes.addClass('d-none');
            textarea.removeClass('required');
            textarea.removeClass('error');
        }
    });

    $('#buscarEndereco').on('click', function () {
        if ($('input[name="cep"]').val() != "") {
            var cep = $('input[name="cep"]').val().replace(/[^0-9]+/g, '');
            $.ajax({
                type: 'GET',
                url: 'https://viacep.com.br/ws/' + cep + '/json',
                success: function (data) {
                    if (!data.erro) {
                        $('input[name="cep"]').val(cep);
                        $('input[name="logradouro"]').val(data.logradouro.substr(0, 1022));
                        $('input[name="bairro"]').val(data.bairro.substr(0, 1022));
                        $('input[name="localidade"]').val(data.localidade.substr(0, 1022));
                        $('select[name="uf"]').val(data.uf);
                        $('select[name="uf"]').change();
                    } else {
                        alert('CEP não encontrado. Por favor, informe o endereço manualmente.');
                    }
                },
                beforeSend: function () {
                    $('#buscarEndereco .spin').removeClass('d-none');
                },
                complete: function (e, textStatus) {
                    if (textStatus == "error") {
                        $('input[name="cep"]').val("");
                        alert('CEP não encontrado. Por favor, informe o endereço manualmente.');
                    }
                    $('#buscarEndereco .spin').addClass('d-none');
                }
            });
        }
    });

    /***********************
     ****** INSCRIÇÃO *******
     ***********************/

    var arrFiles = [];
    var saveData = 0;

    $('#btn-dados').click(function (e) {
        e.preventDefault();

        if (checkFields(this, 'inscricao')) {
            var nome = $.trim($('input[name="nome"]').val());
            $('input[name="nome"]').val(nome);
            createUnitNameFolder();
            changeTabs(this, $(this).attr('href').replace('#', ''));
            $('html, body').animate({scrollTop: ($('#formulario').offset().top)});

            /** Passo 1/4 [Inscrição] - Salvar os anexos na pasta **/
            $('.inscricao .fileupload').fileupload({
                dataType: 'json',
                url: 'server/php/',
                formData: [{name: 'name_dir', value: $('#appYear').val() + '/' + $('#anexos').val()}],
                autoUpload: false,
                sequentialUploads: true,
                maxFileSize: maxFileSize,
                add: function (e, data) {
                    if (!(/(\.|\/)(jpe?g|png|pdf)$/i).test(data.files[0].name)) {
                        $(this).siblings(".error-file").removeClass("d-none");
                        $(this).siblings(".error-file").html("ATENÇÃO: O formato do arquivo deve ser JPG/JPEG, PNG ou PDF");
                        return false;
                    } else if (data.files[0].size > maxFileSize) {
                        $(this).siblings(".error-file").removeClass("d-none");
                        $(this).siblings(".error-file").html("ATENÇÃO: Arquivo não pode ter mais que 8MB");
                        return false;
                    } else {
                        $(this).siblings(".error-file").addClass("d-none");
                        $(this).parents('.wrap-file').removeClass('error');
                        $(this).siblings(".filename.custom-file-label").html(data.files[0].name);
                        for (var i in arrFileUpload) {
                            if (arrFileUpload[i].id == e.delegatedEvent.target.id) {
                                arrFileUpload[i].add = true;
                                arrFileUpload[i].fu = data;
                            }
                        }
                    }
                },
                done: function (e, data) {
                    if (data.textStatus == 'success') {
                        saveData += 1;
                        if (saveData == arrFileUpload.length) {
                            var cpf = $('input[name="cpf"]').val().replace(/[^0-9]+/g, '');
                            $('input[name="cpf"]').val(cpf);

                            var documentos = "&copiaRG=" + $('.filename.copia-rg').html() +
                                "&copiaCPF=" + $('.filename.copia-cpf').html() +
                                "&compResidencia=" + $('.filename.comp-residencia').html() +
                                "&diploma=" + $('.filename.diploma').html() +
                                "&historico=" + $('.filename.historico').html();

                            /** Passo 2/4 [Inscrição] - Salvar candidato no banco **/
                            $.ajax({
                                url: "functions.php",
                                type: 'post',
                                data: {
                                    funcao: 'inserirCandidato',
                                    dados: $('form.dados [name]').not('.ignore').serialize() + documentos
                                },
                                success: function (data) {
                                    if (data != "false") {
                                        var user = JSON.parse(data);
                                        salvarBoleto(user);
                                    } else {
                                        modalError();
                                    }
                                }
                            });
                        }
                    }
                }
            });
        }
    });

    $('#btn-experiencias').click(function (e) {
        e.preventDefault();

        if (checkFields(this, 'inscricao')) {
            changeTabs(this, $(this).attr('href').replace('#', ''));
            $('html, body').animate({scrollTop: ($('#formulario').offset().top)});
        }
    });

    $('#btn-documentos').click(function (e) {
        e.preventDefault();

        if (checkFields(this, 'inscricao')) {
            changeTabs(this, $(this).attr('href').replace('#', ''));
            $('.inscricao .relatorio').empty();

            $.each($('form.dados [name]').not('.ignore').serializeArray(), function (i, field) {
                if (field.name != "anexos") {
                    var row = $('<div/>').addClass('row');
                    var name = field.name.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
                    var col3 = $('<div/>').addClass('col-sm-3 lab').html(name.toUpperCase());
                    var col9 = $('<div/>').addClass('col-sm-9 val').html(field.value != "" ? field.value : "-");
                    row.append(col3);
                    row.append(col9);
                    $('.inscricao .relatorio').append(row);
                }
            });

            $('html, body').animate({scrollTop: ($('#formulario').offset().top)});
        }
    });

    $('#inscrever').click(function (e) {
        e.preventDefault();
        $('#modalInscricao').modal('show');
        for (var i in arrFileUpload) {
            arrFileUpload[i].fu.submit();
        }
    });

    $('.inscricao .voltar').click(function (e) {
        e.preventDefault();
        changeTabs(this, $(this).attr('href').replace('#', ''));
        $('html, body').animate({scrollTop: ($('#formulario').offset().top)});
    });

    function salvarBoleto(user) {
        /** Passo 3/4 [Inscrição] - Salvar boleto (ref.) no banco **/
        $.ajax({
            url: "functions.php",
            type: 'post',
            data: {funcao: 'salvarBoleto', id: user.id, referencia: 'inscricao', parcela: 0},
            success: function (data) {
                if (data != "false") {
                    enviarEmail(user);
                } else {
                    modalError();
                }
            }
        });
    }

    function enviarEmail(user) {
        var nome = $('input[name=nome]').val();
        var email = $('input[name=email]').val();

        /** Passo 4/4 [Inscrição] - Enviar email com boleto **/
        $.ajax({
            url: 'send-email.php',
            type: 'post',
            data: {tipo: 'inscricao', id: user.id, nome: nome, email: email, protocolo: user.protocolo},
            success: function () {
                $('.parabens').removeClass('d-none');
                $('.parabens span.candidato').html(nome);
                $('.parabens span.email').html(email);
                $('.parabens span.protocolo').html(user.protocolo);
                changeTabs('#inscrever', 'boleto');
                $('#modalInscricao').modal('hide');
                $('#inscricaoConcluida').val('true');
                window.onbeforeunload = null;
            }
        });
    }

    /***********************
     ****** MATRICULA *******
     ***********************/

    var holeriteAnexo;
    $('#iniciar-matricula').click(function (e) {
        e.preventDefault();
        changeTabs(this, $(this).attr('href').replace('#', ''));
        $('html, body').animate({scrollTop: ($('#formulario').offset().top)});

        /** Passo 1/6 [Matrícula] - Salvar os anexos na pasta **/
        $('.matricula .fileupload').fileupload({
            dataType: 'json',
            url: 'server/php/',
            formData: [{name: 'name_dir', value: $('#appYear').val() + '/' + $('#anexo').val()}],
            autoUpload: false,
            sequentialUploads: true,
            maxFileSize: maxFileSize,
            add: function (e, data) {
                if (!(/\.(pdf)$/i).test(data.files[0].name)) {
                    $(this).siblings(".error-file").removeClass("d-none");
                    $(this).siblings(".error-file").html("ATENÇÃO: O formato do arquivo deve ser PDF");
                    return false;
                } else if (data.files[0].size > maxFileSize) {
                    $(this).siblings(".error-file").removeClass("d-none");
                    $(this).siblings(".error-file").html("ATENÇÃO: Arquivo não pode ter mais que 8MB");
                    return false;
                } else {
                    $(this).siblings(".error-file").addClass("d-none");
                    $(this).parents('.wrap-file').removeClass('error');
                    $(this).siblings(".filename.custom-file-label").html(data.files[0].name);
                    holeriteAnexo = data;
                }
            },
            done: function (e, data) {
                if (data.textStatus == 'success') {
                    salvarAluno();
                }
            }
        });
    });

    $('#escolha-opcao').click(function (e) {
        e.preventDefault();
        changeTabs(this, $(this).attr('href').replace('#', ''));
        $('html, body').animate({scrollTop: ($('#formulario').offset().top)});
    });

    $('.opcao .plano:not(.disabled)').click(function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: ($('#escolha-opcao').offset().top)});

        $('.opcao .plano').removeClass('selected');
        $(this).removeClass('unselected');
        $(this).addClass('selected');
        $('.opcao .plano:not(.selected)').addClass('unselected');
        $('#escolha-opcao').removeClass('disabled');

        $('.opcao .plano .btn-escolher .selecionar-opcao').html('Selecionar');
        $('.opcao .plano.selected .btn-escolher .selecionar-opcao').html('Selecionado');

        if ($(this).attr('data-opcao') == '1') {
            $('.comprovante-bolsista').addClass('d-none');
        } else {
            $('.comprovante-bolsista').removeClass('d-none');
        }

        if ($(this).attr('data-opcao') != '3') {
            $('.contrato').removeClass('d-none');
        } else {
            $('.contrato').addClass('d-none');
        }
    });

    $('#dados-complementares').click(function (e) {
        e.preventDefault();

        if (checkFields(this, 'matricula')) {
            changeTabs(this, $(this).attr('href').replace('#', ''));
            $('.matricula .relatorio').empty();

            var row = $('<div/>').addClass('infos-relatorio');
            var col3 = $('<div/>').addClass('col-sm-3 lab').html('Opção de Matrícula'.toUpperCase());
            var col9 = $('<div/>').addClass('col-sm-9 val destaque').html($('.opcao .plano.selected').find('.titulo > span').html());
            row.append(col3);
            row.append(col9);
            $('.matricula .relatorio').append(row);

            $.each($('form.dados [name]').not('.ignore').serializeArray(), function (i, field) {
                if (field.name == 'justificativa' && $('.opcao .plano.selected').attr('data-opcao') == '1') {
                    return true;
                }

                if (field.name != 'anexo' && field.name != 'id') {
                    fieldDataName = $('[name="' + field.name + '"]').attr('data-name');
                    lab = fieldDataName ? fieldDataName : field.name;
                    var row = $('<div/>').addClass('infos-relatorio');
                    var col3 = $('<div/>').addClass('col-sm-3 lab').html(lab.toUpperCase());
                    var col9 = $('<div/>').addClass('col-sm-9 val').html(field.value != "" ? field.value : "-");
                    row.append(col3);
                    row.append(col9);
                    $('.matricula .relatorio').append(row);
                }
            });

            $('html, body').animate({scrollTop: ($('#formulario').offset().top)});
        }
    });

    $('#matricular').click(function (e) {
        e.preventDefault();
        $('#modalMatricula').modal('show');

        if ($('.opcao .plano.selected').attr('data-opcao') == '1') {
            salvarAluno();
        } else {
            if (holeriteAnexo) {
                holeriteAnexo.submit();
            }
        }
    });

    $('.matricula .voltar').click(function (e) {
        e.preventDefault();
        changeTabs(this, $(this).attr('href').replace('#', ''));
        $('html, body').animate({scrollTop: ($('#formulario').offset().top)});
    });

    function salvarAluno() {
        var idCandidato = $('input[name="id"]').val();
        var cpf = $('input[name="cpf"]').val().replace(/[^0-9]+/g, '');
        var opcao = $('.opcao .plano.selected').attr('data-opcao');
        $('input[name="cpf"]').val(cpf);

        var dadosAdicionais = "&id-candidato=" + idCandidato +
            "&cpf=" + cpf +
            "&holerite=" + $('.filename > span.holerite').html() +
            "&opcao=" + opcao;

        /** Passo 2/6 [Matrícula] - Salvar aluno no banco **/
        $.ajax({
            url: "functions.php",
            type: 'post',
            data: {
                funcao: 'matricularAluno',
                dados: $('form.dados [name]').not('.ignore').serialize() + dadosAdicionais
            },
            success: function (data) {
                if (data == "true") {
                    if (opcao != 3) {
                        geradorDeBoleto(idCandidato, 'matricula', 0);
                    } else {
                        enviarEmailMatricula(idCandidato, 'bolsista');
                    }
                } else {
                    modalError();
                }
            }
        });
    }

    $('input[name="nome"]').blur(function (e) {
        $('.contrato .contrato-pdf .nome-contrato').html($(this).val());
    });

    $('input[name="rg"]').blur(function (e) {
        $('.contrato .contrato-pdf .rg-contrato').html($(this).val());
    });


    /***********************
     ****** GENÉRICO *******
     ***********************/

    function enviarEmailMatricula(id, url) {
        var nome = $('input[name=nome]').val();
        var email = $('input[name=email]').val();
        var cpf = $('input[name=cpf]').val();
        var rg = $('input[name=rg]').val();
        var folder = $('input[name=anexo]').val();
        var opcao = $('.opcao .plano.selected').attr('data-opcao');

        if (url == undefined) {
            $.ajax({
                url: 'send-email.php',
                type: 'post',
                data: {tipo: 'erro', id: id, nome: nome, email: email},
                complete: function () {
                    $('.quase').removeClass('d-none');
                    $('.quase span.candidato').html(nome);
                    changeTabs('#matricular', 'conclusao');
                    $('#modalMatricula').modal('hide');
                    $('#matriculaConcluida').val('true');
                    window.onbeforeunload = null;
                }
            });
        } else {
            /** Passo 5/6 [Matrícula] - Enviar email com boleto **/
            switch (opcao) {
                case '1':
                case '2':
                    $.ajax({
                        url: 'gerarcontrato.php',
                        type: 'post',
                        data: {nome: nome, cpf: cpf, rg: rg, folder: folder},
                        complete: function () {
                            /** Passo 6/6 [Matrícula] - Enviar email com o contrato **/
                            $.ajax({
                                url: 'send-email.php',
                                type: 'post',
                                data: {
                                    tipo: 'matriculado_' + opcao,
                                    nome: nome,
                                    email: email,
                                    boleto: url,
                                    folder: folder
                                },
                                complete: function () {
                                    $('.parabens.opcao-' + opcao).removeClass('d-none');
                                    $('.parabens.opcao-' + opcao + ' span.candidato').html(nome);
                                    $('#boletoGerado').attr('href', url);
                                    changeTabs('#matricular', 'conclusao');
                                    $('#modalMatricula').modal('hide');
                                    $('#matriculaConcluida').val('true');
                                    window.onbeforeunload = null;
                                }
                            });
                        }
                    });

                    break;
                case '3':
                    $.ajax({
                        url: 'send-email.php',
                        type: 'post',
                        data: {tipo: 'bolsista', nome: nome, email: email},
                        complete: function () {
                            $('.parabens.opcao-' + opcao).removeClass('d-none');
                            $('.parabens.opcao-' + opcao + ' span.candidato').html(nome);
                            changeTabs('#matricular', 'conclusao');
                            $('#modalMatricula').modal('hide');
                            $('#matriculaConcluida').val('true');
                            window.onbeforeunload = null;
                        }
                    });
                    break;
            }
        }
    }

    function modalError() {
        $(".msg").addClass("d-none");
        $(".fail").removeClass("d-none");
        $("#closeModal").removeClass("d-none");
        window.onbeforeunload = null;
    }

    function geradorDeBoleto(id, tipoBoleto, parcela) {
        var cep = $('input[name="cep"]').val().replace(/[^0-9]+/g, '');
        var cpf = $('input[name="cpf"]').val().replace(/[^0-9]+/g, '');
        $('input[name="cep"]').val(cep);
        $('input[name="cpf"]').val(cpf);
        var dadosCandidato = $('form.dados [name]').not('.ignore').serialize() +
            "&cpf=" + cpf;

        /** Passo 3/6 [Matrícula] - Gerar boleto **/
        $.ajax({
            type: 'POST',
            url: 'boleto.php',
            data: {funcao: tipoBoleto, dados: dadosCandidato},
            success: function (retornoCaixa) {
                retornoCaixa = JSON.parse(retornoCaixa);

                /** Passo 4/6 [Matrícula] - Salvar boleto no banco **/
                $.ajax({
                    url: "functions.php",
                    type: 'post',
                    data: {
                        funcao: 'salvarBoleto',
                        id: id,
                        referencia: tipoBoleto,
                        parcela: parcela,
                        descricao: retornoCaixa.boleto
                    },
                    success: function (data) {
                        if (data != "false") {
                            enviarEmailMatricula(id, retornoCaixa.url);
                        } else {
                            modalError();
                        }
                    }
                });
            }
        });
    }

    function changeTabs(elem, rel) {
        $(elem).tab('show');
        $(elem).removeClass('active');
        $('.nav-tabs li').removeClass('active');
        $('.nav-tabs li[data-rel="' + rel + '"]').addClass('active');
    }

    function checkFields(ref, parent) {
        var current = $(ref).parents('.' + parent);

        /* Input Text */
        current.find('input[type=text].required').each(function (e, item) {
            if ($(item).val() == '') {
                $(item).addClass('error');
            } else {
                $(item).removeClass('error');
            }
        });

        var email = current.find('input[name="email"]');
        var checkemail = current.find('input[name="checkemail"]');
        if ($(email).val() != $(checkemail).val()) {
            $(checkemail).addClass('error');
        } else {
            $(checkemail).removeClass('error');
        }

        var parse_email = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
        if (!parse_email.test($(email).val())) {
            $(email).addClass('error');
        } else {
            $(email).removeClass('error');
        }

        /* Textarea */
        current.find('textarea.required').each(function (e, item) {
            if ($('.opcao .plano.selected').attr('data-opcao') != '1') {
                if ($(item).val() == '') {
                    $(item).addClass('error');
                } else {
                    $(item).removeClass('error');
                }
            } else {
                $(item).removeClass('error');
                $(item).val('');
            }
        });

        /* Input Radio */
        if (current.find('input[name="sexo"]:checked').length <= 0) {
            current.find('input[name="sexo"] + label').addClass('error');
        } else {
            current.find('input[name="sexo"] + label').removeClass('error');
        }

        if (current.find('input[name="trabalho"]:checked').length <= 0) {
            current.find('input[name="trabalho"] + label').addClass('error');
        } else {
            current.find('input[name="trabalho"] + label').removeClass('error');
        }

        current.find('.radio-experiencia').each(function () {
            if ($(this).find('input.experiencia-condicional:checked').length <= 0) {
                $(this).find('input.experiencia-condicional + label').addClass('error');
            } else {
                $(this).find('input.experiencia-condicional + label').removeClass('error');
            }
        });

        /* Select */
        current.find('select.required').each(function (e, item) {
            if ($(item).val() == "") {
                $(item).addClass('error');
            } else {
                $(item).removeClass('error');
            }
        });

        /* Input File */
        current.find('input[type=file].required').each(function (e, item) {
            if ($('.opcao .plano.selected').attr('data-opcao') != '1') {
                if ($(item).siblings('.filename').html() == "" || $(item).siblings('.filename').html().trim().toLowerCase() == "escolha o arquivo") {
                    $(item).parents('.wrap-file').addClass('error');
                } else {
                    if ($(item).siblings('.error-file').hasClass('d-none')) {
                        $(item).parents('.wrap-file').removeClass('error');
                    }
                }
            } else {
                $(item).parents('.wrap-file').removeClass('error');
            }
        });

        /* Input Checkbox*/
        if ($('.opcao .plano.selected').attr('data-opcao') != '3') {
            if (current.find('.aceite input.selecao').first().prop('checked')) {
                current.find('.aceite').first().removeClass('error');
            } else {
                current.find('.aceite').first().addClass('error');
            }
        } else {
            current.find('.aceite').first().removeClass('error');
        }

        if (current.find('.error').length <= 0) {
            return true;
        } else {
            $('html, body').animate({scrollTop: (current.find('.error').first().offset().top - 30)});
            return false;
        }
    }

    function createUnitNameFolder() {
        var nome = $('input[name="nome"]').val();
        var folder = nome.replace(/[^_a-zA-Z 0-9]+/g, '').replace(/\s/g, '_').toLowerCase();
        folder = folder + '_' + $('input[name="cpf"]').val().replace(/[^0-9]+/g, '');
        $('#anexos').val(folder);
    }
});

function fillFormDev() {
    $('form.dados [name="nome"]').val("Wilmax");
    $('form.dados [name="email"]').val("wilmaxmcruz@gmail.com");
    $('form.dados [name="checkemail"]').val("wilmaxmcruz@gmail.com");
    $('form.dados [name="telefone"]').val("(16) 99320-5959");
    $('form.dados [name="cpf"]').val("05981238658");
    $('form.dados [name="rg"]').val("13021334");
    $('form.dados [name="dia"]').val("7");
    $('form.dados [name="mes"]').val("3");
    $('form.dados [name="ano"]').val("1984");
    $('form.dados [name="sexo"][value="Masculino"]').prop('checked', true);
    $('form.dados [name="cep"]').val("05417040");
    $('form.dados [name="logradouro"]').val("Rua João Miguel Jarra");
    $('form.dados [name="numero"]').val("105");
    $('form.dados [name="bairro"]').val("Vila Madalena");
    $('form.dados [name="localidade"]').val("São Paulo");
    $('form.dados [name="uf"]').val("SP");
    $('form.dados [name="motivacao"]').val("Lorem ipsum dolor sit amet");
    $('form.dados [name="trabalho"][value="Não"]').prop('checked', true);
}


/******************************************
 ****** FILTRO DE ALUNOS POR HORARIO ******
 ******************************************/

$('.defesa-isotope').isotope({
    itemSelector: '.item-isotope',
    layoutMode: 'vertical'
});

// todos
$('#btn-todos').click(function () {
    $('.defesa-isotope').isotope({filter: '*'});
});

// 8h
$('#btn-8h').click(function () {
    $('.defesa-isotope').isotope({filter: '.h8'});
});

// 10h
$('#btn-10h').click(function () {
    $('.defesa-isotope').isotope({filter: '.h1030'});
});

// 14h
$('#btn-14h').click(function () {
    $('.defesa-isotope').isotope({filter: '.h14'});
});

// 16h
$('#btn-16h').click(function () {
    $('.defesa-isotope').isotope({filter: '.h1630'});
});

/************************************************
 ****** FILTRO DE ALUNOS POR LETRA INICIAL ******
 ************************************************/
function orderByName(letra) {
    $('.defesa-isotope').isotope({filter: letra});
}

